import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    building: null,
    parsed_building: {
      floors: null,
    },
    current_contacts: [],
    cc_data: null,
    location: {
      lat: null,
      lng: null,
      range: 0,
    },
    call: null,
  },
  getters: {
    building(state) {
      return state.building;
    },
    parsed_building(state) {
      return state.parsed_building;
    },
    qr_location(state) {
      return state.location;
    },
    current_contacts(state) {
      return state.current_contacts;
    },
    cc_data(state) {
      return state.cc_data;
    },
    call(state) {
      return state.call;
    },
  },
  mutations: {
    SET_BUILDING(state, value) {
      state.building = value;
    },
    PARSE_BUILDING(state, value) {
      if (value) {
        // Floors
        let floors = [];
        value.floor_positions.forEach((floor) => {
          let f = value.floors.find((f) => f.id === floor);
          let f_index = value.floors.findIndex((f) => f.id === floor);
          //Apartments
          let apartments = [];
          value.floors[f_index].apartment_positions.forEach((apartment) => {
            let a = f.apartments.find((a) => a.id === apartment);
            apartments.push({name: a.name, id: a.id, alias: a.alias, mode: a.mode, remarks: a.remarks});
          })
          if (apartments.length > 0) {
            floors.push({name: f.name, id: f.id, apartments: apartments});
          }
        });
        state.parsed_building.floors = floors;

        state.location = value.location;
      } else {
        state.parsed_building = null;
        state.location = null;
      }
    },
    SET_CONTACTS(state, value) {
      state.current_contacts = value.contacts;
    },
    SET_CCDATA(state, value) {
      state.cc_data = value.cc_data;
    },
    SET_CALL(state, value) {
      state.call = value;
    }
  },
  actions: {
    async fetchBuilding({ commit }, QRId) {
      await Vue.axios.get("https://us-central1-tappbellcloud.cloudfunctions.net/getVirtualQRBuilding/" + QRId)
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
            commit("SET_BUILDING", response.data);
            commit("PARSE_BUILDING", response.data);
          } else {
            //console.log('Response Error 404');
            commit("SET_BUILDING", null);
            commit("PARSE_BUILDING", null);
          }
      })
      .catch((error) => {
        //console.log('catched error', error);
        commit("SET_BUILDING", null);
        commit("PARSE_BUILDING", null);
      });
    },
    async fetchContacts({ commit }, params) {
      await Vue.axios.get("https://us-central1-tappbellcloud.cloudfunctions.net/getVirtualQRContacts/" + params.QRId + "/" + params.floorId + "/" + params.apartmentId)
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
            commit("SET_CONTACTS", response.data);
            commit("SET_CCDATA", response.data);
          } else if (response.status == 204) {
            commit("SET_CONTACTS", []);
            commit("SET_CCDATA", []);
          } else {
            //console.log('Response Error 404');
            commit("SET_CONTACTS", null);
            commit("SET_CCDATA", null);
          }
      })
      .catch((error) => {
        //console.log('catched error', error);
        commit("SET_CONTACTS", null);
        commit("SET_CCDATA", null);
      });
    },
    async saveCall({ commit }, params) {
      await Vue.axios.post("https://us-central1-tappbellcloud.cloudfunctions.net/setVirtualQRCall/" + params.QRId + "/" + params.floorId + "/" + params.apartmentId,params.call)
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
            commit("SET_CALL", response.data);
          } else {
            //console.log('Response Error 404');
            commit("SET_CALL", null);
          }
      })
      .catch((error) => {
        console.log('catched error', error);
      });
    },
  },
})
